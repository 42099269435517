<script setup>
import { jwtDecode } from 'jwt-decode'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { usersRef } from '@/plugins/firebase-db'
import { doc, getDoc } from 'firebase/firestore'

import NavBar from '@/components/nav-bar.vue'
import { ROUTES } from '@/router/routes'
import { useAppStore } from '@/stores/app'
import { validateToken, wrapUrl } from '@/utils/auth'

const store = useAppStore()
const route = useRoute()
const router = useRouter()

const apiResponse = ref(null)

validateToken()
  .then(async token => {
    const decoded = jwtDecode(token)
    const id = decoded.data.user.id

    const document = await getDoc(doc(usersRef, id))
    store.$patch({ currentUser: document.exists() ? document.data() : null })

    if (route.name === ROUTES.HOME) router.push({ name: ROUTES.PROJECTS })
  })
  .catch(() => router.push({ name: ROUTES.HOME }))

onMounted(async () => {
  await apiHealthCheck()
})

async function apiHealthCheck() {
  try {
    const res = await fetch(wrapUrl('https://generatorn.com:3000'))
    if (res.ok) {
      apiResponse.value = {
        status: 'success',
        message: 'API is available',
      }
      return true
    } else {
      apiResponse.value = {
        status: 'error',
        message: 'API is not available',
      }
      return false
    }
  } catch (error) {
    apiResponse.value = {
      status: 'error',
      message: 'API is not available',
    }
    return false
  }
}
</script>

<template>
  <div class="flex min-h-screen w-full flex-col items-center">
    <template v-if="!store.currentUser && route.name !== ROUTES.HOME">
      <div class="loading loading-lg mx-auto mt-16" />
    </template>
    <template v-else>
      <NavBar />
      <div v-if="apiResponse && apiResponse.status === 'error'" class="py-4">
        <BaseAlert type="error">
          {{ apiResponse.message }}
        </BaseAlert>
      </div>
      <div class="flex w-full max-w-2xl pt-6">
        <router-view :key="$route.fullPath" class="flex-1" />
      </div>
    </template>
  </div>
</template>
